import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  Button,
  List,
  ListItem,
  Container,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";

import { FancyHr } from "../FancyHr";

import { StyledGeneology } from "./Geneology.styled";

import ImgGeneology from "../../images/geneology/geneology.png";

const Geneology = ({ children }) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const schedule = [
    {
      week: "FIRST WEEK – April 9, 2024 - 4:00 p.m. (PST)",
      topics: [
        {
          title: "African American Genealogy?",
          items: ["How to get started?"],
        },
        {
          title: "Gathering your information:",
          items: [
            "Interview family members.",
            "How to conduct oral interviews with family (Interview questions)",
            "What documents will I search for? (i.e., obituary, photo, newspapers)",
            "Where to look - Google, Facebook, Wiki",
            "Family Group Sheet",
          ],
        },
        {
          title: "Organizing Data",
          items: null,
        },
        {
          title: "Tools:",
          items: [
            "Binder - 3”",
            "Divider Tab",
            "Label Maker",
            "Laptop/Desktop computer",
            "Printer/Scanner",
            "Option: Dual Monitors",
          ],
        },
      ],
    },
    {
      week: `SECOND WEEK – April 23, 2024 - 4:00 p.m. (PST)`,
      topics: [
        {
          title: "Genealogy software: Why purchase software and the benefits?",
          items: [
            ` Tribal Pages Software (free)`,
            `Legacy Family Software `,
            `Root Tech family Software `,
          ],
        },
        {
          title: "Navigating Resource Sites: How to access?",
          items: [` Ancestry.com`, `FamilySearch`, `Fold 3`, `Newspaper.com`],
        },
      ],
    },
    {
      week: `THIRD WEEK – May 14, 2024 - 4:00 p.m. (PST)`,
      topics: [
        {
          title: null,
          items: [
            `Writing your research notes`,
            `Three resources to verify the facts of your research.`,
          ],
        },
      ],
    },
    {
      week: `FOURTH WEEK – May 28, 2024 - 4:00 p.m. (PST)`,
      topics: [
        {
          title: `Analyzing and Sourcing Your Research Data. Part 1`,
          items: [
            ` Writing your research notes`,
            `Three resources to verify the facts of your research.`,
          ],
        },
      ],
    },
    {
      week: `FIFTH WEEK – June 11, 2024 - 4:00 p.m. (PST) `,
      topics: [
        {
          title: `Analyzing and Sourcing Your Research Data. Part 2`,
          items: [
            ` Searching for deeds and land records.`,
            `Searching for obituaries and city directories.`,
          ],
        },
      ],
    },
    {
      week: `SIXTH WEEK – June 25, 2014 - 4:00 p.m. (PST)`,
      topics: [
        {
          title: `Analyzing and Sourcing Your Research Data. Part 3`,
          items: [
            `Researching military records`,
            `Researching the U. S. Freeman Bureau`,
          ],
        },
      ],
    },
    {
      week: `SEVENTH WEEK – Bonus – July 9, 2024 – 4:00 p.m. (PST)`,
      topics: [
        {
          title: `Using photo software`,
          items: null,
        },
      ],
    },

    // Continue with other weeks in the same structure...
  ];
  return (
    <StyledGeneology>
      <Box py={isSmall ? 2 : 13} px={isSmall ? 2 : "initial"}>
        <Grid container>
          <Grid item xs={12} md={3}></Grid>
          <Grid item xs={12} md={6}>
            <Box display="flex" justifyContent="center">
              <Typography variant="h4" align="center">
                Soul’s Family Genealogy Research
              </Typography>
            </Box>
            <FancyHr center />
            <Typography paragraph align="center">
              Beginner to Intermediate Guide on African American Genealogy
            </Typography>
            <Box display="flex" justifyContent={`center`}>
              <Box component="img" src={ImgGeneology} alt="geneology" />
            </Box>
          </Grid>
          <Grid item xs={12} md={3}></Grid>
        </Grid>
      </Box>
      <Box
        py={isSmall ? 2 : 13}
        px={isSmall ? 2 : "initial"}
        style={{ backgroundColor: `#EFEFEF` }}
      >
        <Grid container>
          <Grid item xs={12} md={2}></Grid>
          <Grid item xs={12} md={8}>
            {schedule.map((week, weekIndex) => (
              <Card key={weekIndex} style={{ marginBottom: 20 }}>
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    {week.week}
                  </Typography>
                  {week.topics.map((topic, topicIndex) => (
                    <Box key={topicIndex} mb={2}>
                      <Typography variant="h6">{topic.title}</Typography>
                      {topic.items && (
                        <List>
                          {topic.items.map((item, itemIndex) => (
                            <ListItem key={itemIndex}>{item}</ListItem>
                          ))}
                        </List>
                      )}
                    </Box>
                  ))}
                </CardContent>
              </Card>
            ))}
            <Button
              variant="contained"
              color="primary"
              href="https://forms.gle/X3wVs8kqyPHKcY8VA"
              target="_blank"
            >
              Registration Click Here
            </Button>
          </Grid>
          <Grid item xs={12} md={2}></Grid>
        </Grid>
      </Box>
    </StyledGeneology>
  );
};

Geneology.propTypes = {
  children: PropTypes.node,
};

export default Geneology;
